import React from 'react'
import styled from 'styled-components'
import { NeedHelp } from '../components/NeedHelp'
import { Root } from '../components/Root'
import { v } from '../styles/variables'
import { Button } from '../ui/buttons'
import { Card } from '../ui/cards'
import { DIV } from '../ui/tags'
import { url } from '../urls'
import '../styles/global'

const Home = () => (
  <Root>
    <Row>
      <Card pb4 relative>
        <h3>Our Mission</h3>
        <p>
          The sole mission of the Pacific Northwest Hospitals and Institutions
          committee is to carry the message of Alcoholics Anonymous to persons
          confined in any facility, government or private, where there are
          alcoholics. All AA panel meetings of this Committee are to be
          conducted at the invitation of each facility.
        </p>
        <Actions>
          <Button to={url.ourMission}>Read more about our mission</Button>
        </Actions>
      </Card>
      <Card pb4 relative>
        <h3>Get Involved</h3>
        <p>
          We&apos;re always looking for more people to speak at treament centers
          and prisons. Service positions at the comittee level are also
          available, and there are a number of ways to help spread the word!
        </p>
        <Actions>
          <Button to={url.getInvolved}>
            Learn more about getting involved
          </Button>
        </Actions>
      </Card>
    </Row>
    <NeedHelp mt2 />
  </Root>
)

const Actions = p => <DIV absolute b1 l0 ml1 mr1 {...p} />

// prettier-ignore
const Row = styled.div`
  @media (min-width: 650px) {
    display: flex;

    ${Card} {
      width: 50%;

      &:first-child {
        margin-right: ${v.s1};
      }
    }
  }

  @media (max-width: 649px) {
    ${Card}:not(:last-child) {
      margin-bottom: ${v.s1};
    }
  }
`

export default Home
